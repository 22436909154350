<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Bios</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="bios-form">
          <v-text-field
            label="Name *"
            v-model="fields.name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>

          <v-select
            label="Teams *"
            :items="teams"
            v-model="fields.teams"
            item-value="id"
            item-text="display_name"
            background-color="white"
            outlined
            multiple
          ></v-select>

          <v-file-input
            v-model="fields.photo"
            placeholder="Choose a photo Image"
            prepend-icon="mdi-camera"
            label="Photo *"
            @change="photoHasChanged()"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('photo')"
            :error-messages="errors['photo']"
          ></v-file-input>

          <v-file-input
            v-model="fields.thumbnail"
            placeholder="Choose a thumbnail Image"
            prepend-icon="mdi-camera"
            label="Thumbnail *"
            @change="thumbnailHasChanged()"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('thumbnail')"
            :error-messages="errors['thumbnail']"
          ></v-file-input>

          <v-textarea
            label="Description *"
            v-model="fields.description"
            rows="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('description')"
            :error-messages="errors['description']"
          ></v-textarea>

          <v-select
            :items="images"
            label="Background Image"
            item-text="url"
            item-value="id"
            v-model="fields.background_image"
            outlined
            background-color="white"
          >
            <template v-slot:selection="{ item, index }">
              <img width="48" :src="item.asset_urls.url" />
            </template>
            <template v-slot:item="{ item }">
              <img width="48" :src="item.asset_urls.url" />
            </template>
          </v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="bios-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        thumbnail_has_changed: false,
        photo_has_changed: false,
        name: null,
        description: null,
        background_image: null,
        thumbnail: null,
        photo: null,
        teams: [],
      },
      bios: null,
      errors: {},
    };
  },

  computed: {
    teams() {
      return this.$store.getters["includem/teamsStore/all"];
    },

    images() {
      return this.$store.getters["includem/biosStore/allImages"];
    },
  },

  methods: {
    photoHasChanged() {
      this.fields.photo_has_changed = true;
    },

    thumbnailHasChanged() {
      this.fields.thumbnail_has_changed = true;
    },

    openForm: function (bios = null) {
      if (bios !== null) {
        this.isEditing = true;
        this.bios = bios;
        this.fields.name = bios.name;
        this.fields.teams = bios.teams;

        this.fields.teams = bios.teams.map((c) => c.id);

        this.fields.description = bios.description;

        if (bios.background_image) {
          this.fields.background_image = bios.background.id;
        }

        this.fields.thumbnail = bios.thumbnail
          ? new File([bios.thumbnail], bios.thumbnail)
          : null;

        this.fields.photo = bios.photo
          ? new File([bios.photo], bios.photo)
          : null;
      }

      this.dialog = true;
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.bios = null;
      this.fields.name = null;
      this.fields.background_image = null;
      this.fields.thumbnail = null;
      this.fields.photo = null;
      this.fields.description = null;
      this.fields.teams = [];
      (this.fields.thumbnail_has_changed = false),
        (this.fields.photo_has_changed = false),
        (this.errors = {});
    },

    saveForm: function () {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.bioId = this.bios.id;
      }

      this.$store
        .dispatch("includem/biosStore/saveBio", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
